<template>
  <!-- begin::Page loader -->
  <div class="page-loader page-loader-logo">
    <img alt="Logo"
         src="/image/side-logo.png" />
    <div class="spinner mb-10"
         :class="spinnerClass || 'spinner-primary'"></div>
    <slot></slot>
  </div>
  <!-- end::Page Loader -->
</template>

<script>
export default {
  name: 'Loader',
  props: {
    spinnerClass: String,
  },
}
</script>
