<template>
  <transition name="fade-in-up">
    <div class="callback vh-100 d-flex align-items-center justify-content-center">
      <Loader>
        <p>{{ $ml.get('pleaseWaitingRedirectToBack') }}</p>
      </Loader>
      <p>{{ error }}</p>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex'
import ApiService from '@/core/services/api.service'
import Loader from '@/view/content/Loader.vue'
import { LOGIN, LOGOUT } from '@/core/services/store/auth.module'
import JwtService from '@/core/services/jwt.service'
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from '@/core/services/store/htmlclass.module.js'

export default {
  data() {
    return {
      error: null,
    }
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading')
  },
  computed: {
    ...mapGetters(['prodEnv']),
  },
  mounted() {
    ApiService.post(`${process.env.VUE_APP_LOGIN_URL}v2/oauth/token`, {
      grant_type: 'authorization_code',
      client_id: process.env.VUE_APP_CLIENT_ID,
      client_secret: process.env.VUE_APP_CLIENT_SECRET,
      redirect_uri: process.env.VUE_APP_REDIRECT_URI,
      code: this.$route.query ? this.$route.query.code : '',
    })
      .then((res) => {
        // clear existing errors
        this.$store.dispatch(LOGOUT)

        this.$store.dispatch(LOGIN, res).then(() => {
          let currentRoute = JwtService.getCurrentRoute()
          if (currentRoute) {
            JwtService.destroyCurrentRoute()
            this.$router.push(currentRoute)
          } else {
            this.$router.push({ name: 'general' })
          }
        })

        // Remove page loader after some time
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading')
      })
      .catch((err) => {
        if (this.$route.query) {
          if (this.$route.query.error === 'access_denied') {
            window.location.href = this.loginUrl
          }
        }
        console.log('Login Error!, error message: ', err)
        this.error = err.toString()
        // this.$router.push("/login");
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading')
      })
  },
  computed: {
    ...mapGetters(['prodEnv']),
    loginUrl() {
      return `${process.env.VUE_APP_LOGIN_URL}v2/oauth/authorize?client_id=${process.env.VUE_APP_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_REDIRECT_URI}&response_type=code&scope=`
    },
  },
  components: {
    Loader,
  },
}
</script>
